<template>
  <div>
    <div>
      <custom-table
        ref="customTable"
        :columns="columns"
        :custom-view="customView"
        :footer-actions="footerActions"
        :sums="sums"
        table-height="calc(100vh - 145px)"
        api-url="/dc-distributor-list"
        @showDialogCreate="showDialogCreate"
      ></custom-table>
    </div>

    <v-dialog v-model="createDialog" max-width="640px" @input="refreshData">
      <DistributorCreate
        v-if="createDialog"
        :select-item="selectItem"
        @close="hideDialogCreate"
      />
    </v-dialog>
  </div>
</template>

<script>
import { CUSTOM_VIEW_NORMAL } from "@/libs/const";

export default {
  name: "Distributor",
  components: {
    DistributorCreate: () => import("@/components/dc/DistributorCreate"),
  },

  props: {},
  data: () => ({
    customView: CUSTOM_VIEW_NORMAL,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    createDialog: false,
    selectItem: {},
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },

  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const { customer } = window.me;
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time"),
        placeholder: this.$t("labels.create_time"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        dateOnly: true,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.dc_distributor"),
        placeholder: this.$t("labels.dc_distributor"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.phone"),
        placeholder: this.$t("labels.phone"),
        name: "phone",
        hasSort: false,
        sortName: "phone",
        key: "phone",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.city"),
        placeholder: this.$t("labels.city"),
        name: "city_name",
        hasSort: false,
        sortName: "city_name",
        key: "city_name",
        customClass: 'text-capitalize',
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.district"),
        placeholder: this.$t("labels.district"),
        name: "county_name",
        hasSort: false,
        sortName: "county_name",
        key: "county_name",
        customClass: 'text-capitalize',
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.ward"),
        placeholder: this.$t("labels.ward"),
        name: "ward_name",
        hasSort: false,
        sortName: "ward_name",
        key: "ward_name",
        customClass: 'text-capitalize',
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.address"),
        placeholder: this.$t("labels.address"),
        name: "address",
        hasSort: false,
        sortName: "address",
        key: "address",
        required: true,
      },
    ];

    if (!customer.dc_id_distributor) {
      columns.push(
        ...[
          {
            type: "input-filter",
            label: this.$t("labels.level"),
            placeholder: this.$t("labels.level"),
            name: "level",
            hasSort: false,
            sortName: "level",
            key: "level",
            required: true,
          },
          {
            type: "input-filter",
            label: this.$t("labels.dc_distributor_parent"),
            placeholder: this.$t("labels.dc_distributor_parent"),
            name: "parent_name",
            hasSort: false,
            sortName: "parent_name",
            key: "parent_name",
            required: true,
          },
        ]
      );
    }

    columns.push({
      type: "button",
      label: this.$t("labels.note"),
      headerAction: "showDialogCreate",
      headerActionColor: "primary",
      headerActionLabel: this.$t("labels.add"),
      action: "showDialogCreate",
      actionColor: "warning",
      actionLabel: this.$t("labels.edit"),
      required: true,
    });

    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    showDialogCreate(item) {
      this.selectItem = { ...item };
      this.createDialog = true;
    },
    hideDialogCreate() {
      this.selectItem = {};
      this.createDialog = false;
      this.refreshData();
    },
    refreshData() {
      this.$refs.customTable.getList();
    },
  },
};
</script>
